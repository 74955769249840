import React, { useState, useEffect } from "react";
import Header from "../commom/Header";
import {
Container,
Image,
Row,
Col,
Form,
Button,
Accordion,
} from "react-bootstrap";

import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../commom/Footer";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import {
  getCources,contactUs
} from "../../store/api/course.api";
import { toast } from 'react-toastify';

const HomeNew = () => {
const navigate = useNavigate();
const user = useSelector((state) => state.auth.user);
const { isLoggedIn } = useSelector((state) => state.auth);
const [allCources, setAllCources] = useState();
const [value, setValue] = useState({ email: "" });
if (isLoggedIn) {
navigate("/my-course-details", { replace: true });
}
const dispatch = useDispatch();

// Add class to body on mount and clean up on unmount
useEffect(() => {
  document.body.classList.add('full-bg-black'); 

  return () => {
    document.body.classList.remove('full-bg-black'); 
  };
}, []);

const settings1 = {
  dots: false,
  infinite: false,
  arrows: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  centermode: true,
  centerPadding: "0px",
  autoplay: true,
};
const settings2 = {
  dots: false,
  arrows: false,
  infinite: true,
  slidesToShow: 6,
  pauseOnHover: true,
  autoplay: true,
  speed: 5000,
  autoplaySpeed: 5000,
  cssEase: "linear",
  centerMode: true,
};

useEffect(() => {
  let dataFilter = {
    filter: {
      is_delete: 0,
    },
  };
  dispatch(getCources(dataFilter)).then((course) => {
    setAllCources(course.payload.data.courses);

  });
 
}, []);


const gotoPage = (page) =>{
  navigate(page)
}

const onChange = (e) => {
  setValue({
      ...value,
      [e.target.name]: e.target.value,
  });
}; 
const onAdd = () => {

  dispatch(contactUs(value))
      .unwrap()
      .then((res) => {
          if (res.status === 200) {
              toast.success(res.message)
             // navigate('/my-course-details'); 
             setValue({ email: "" });
          }

      })
      .catch(() => {

      });
};
return (
<div className="d-flex flex-column flex-grow-1 homepage homepage-bg">
  <Header />
  <div className="page-wrapper flex-grow-1 py-0">
    <section className="banner-wrapper home-banner position-relative bg-wrap">
      <Container>
        <div className="banner-content-area" style={{ maxWidth: "745px" }}>
          <div className="block font-32 text-center fw-light video-style-font">
            <h1 className="font-64 mb-md-3 ">
            Er partner för <br className="d-md-block d-none" /> intern utbildning
            </h1>
            <p className="font-25">
            Vi hjälper er att skapa skräddarsydda utbildnignsfilmer - från planering och förproduktion till färdiga utbildningsfilmer och verktyg för uppföljning.
            </p>
          </div>
          <div className="text-center mt-3">
          <a onClick={()=>gotoPage('/contact-new')}  
              class="btn font-20 mobile-font-16 btn-gradient rounded-pill">
              <span> Kontakt </span>
            </a>
          </div>
        </div>
        <a href="javascript:;"
          className="see-more text-white d-flex flex-column align-items-center justify-content-center">
          <span className="d-block">Se mer</span>{" "}
          <span className="icon-left-angle-icon icon-bottom-angle"></span>
        </a>
        <div className="banner-video">
          <video src="/assets/video/Background-flow.mp4" autoplay="autoplay" playsinline="playsinline" muted="muted"
            loop="loop"></video>
        </div>
      </Container>
    </section>

    <section className="PlannigSection top-index">
      <Container fluid>
        <div className="PlannigSection-titlewrap text-center">
          <h2 className="font-40 fw-light mb-lg-3 mb-0">
            Så här går det till
          </h2>
          <p className="font-25 fw-medium">
          Vi kan hjälpa till i en del eller flera
          </p>
        </div>
        <Row className="gy-3 position-relative pt-md-0">
          <Col md={6}>
          <div className="PlannigSection-imgwrap">
            <Image src="/assets/images/Planering.png" alt="Office" className="w-100" />
            <Form.Group className="PlannigSection-form mb-0" controlId="exampleForm.ControlInput1">
              <Form.Control type="email" name='email' placeholder="name@example.com" value={value.email} onChange={onChange} />
              <Button variant="primary" onClick={onAdd} type="button" >Anslut</Button>
            </Form.Group>
          </div>
          </Col>
          <Col md={6}>
          <div className="mobile-pt-20 mobile-full">
            <h2 className="font-40 fw-lighter mb-1">
              Planering & förproduktion
            </h2>
            <h4 className="fw-medium font-24 mt-1 margin-b-16">
            För er som behöver hjälp att utforma er utbildning
            </h4>
            <div className="block fw-lighter font-20 line-h-small mobile-font-13">
              <p>
                Oavsett om ert syfte är att utbilda inom ett internt
                dataprogram med enbart en skärm, eller en
                säkerhetsgenomgång kring er anläggning kan vi vara med och
                hjälpa er att planera upplägget från start.
              </p>
              <p>
                Hur vill ni att innehållet ska presenteras och av vem?
                Vilken inspelningsplats fungerar bäst för syftet? Hur får
                vi kursen att spegla företagets kultur och ton? Önskar ni
                retorisk hjälp utifrån, eller en extern kurshållare som
                skräddarsyr en utbildning till er personal?{" "}
              </p>
              <p>Vi hjälper er med dessa frågor och mycket mer.</p>
            </div>
          </div>
          </Col>
        </Row>
      </Container>
    </section>

    <section className="ProduktionSection top-index">
      <Container fluid>
        <Row className="gy-md-3 position-relative">
          <Col md={6} className="order-md-0 order-1">
          <div className="d-flex flex-column h-100 mt-sm-0 mobile-mt-20">
            <h2 className="font-40 fw-lighter mb-1">Produktion</h2>
            <h4 className="fw-medium font-24 mt-md-1 margin-b-16">
              Utbildningsfilmer som engagerar
            </h4>
            <div className="block fw-lighter font-20 mobile-font-13 mobile-mb-20">
              <p>
                Vi riggar er inspelningsplats med kvalitetsutrustning för
                inspelning, belysning och ljud, oavsett om det är i vår
                studio eller på plats ute hos er.
              </p>
              <p>
                Vi sköter sedan all redigering och lägger till eventuell
                grafik, ljud och/eller animationer för att göra innehållet
                mer engagerande och skräddarsytt enligt er grafiska
                profil.
              </p>
            </div>
          </div>
          </Col>
          <Col md={6} className="order-md-1 order-0">
          <div className="ProduktionSection-imgwrap">
            <Image src="./assets/images/Produktion.png" alt="" className="w-100" />
          </div>
          </Col>
        </Row>
      </Container>
    </section>

    <section className="followupSection top-index">
      <Container fluid>
        <Row className="gy-3 position-relative pt-md-0">
          <Col md={6}>
          <div className="">
            <Image src="/assets/images/Uppföljning.png" alt="Office" className="w-100" />
          </div>
          </Col>
          <Col md={6}>
          <div className="maxw-443 mobile-pt-20 mobile-full">
            <h2 className="font-40 fw-lighter mb-1">Uppföljning</h2>
            <h4 className="fw-medium font-24 mt-1 margin-b-16">
              Distribution av er utbildning
            </h4>
            <div className="block fw-lighter mb-8 font-20 line-h-small mobile-font-13">
              <p>
                Vi hjälper er distribuera er utbildningsfilm på ert
                interna intranät eller, om ni önskar, på vår plattform.
              </p>
              <p>
                Väljer ni vår plattform får ni tillgång till våra
                administratörsverktyg;som att tilldela medarbetare
                kursen, sätta deadlines och följa medarbetarnas
                utveckling.{" "}
              </p>
              <p>
                Här finns även möjlighet att skapa unika diplom till de
                som slutfört utbildningen samt tillgång till vårt
                utbildningsbibliotek med kurser som Office 365, juridik,
                försäljning, retorik m.m.
              </p>
            </div>
          </div>
          </Col>
        </Row>
      </Container>
    </section>

    <section className="InterestingSection top-index">
      <Container>
        <div className="text-center ">
          <h2 className="font-64 mobile-font-30 fw-semibold ">
            Låter det intressant?
          </h2>
          <p className="font-24 mobile-font-13 fw-light">
            Kontakta oss om du vill veta mer om hur vi kan hjälpa er att
            skapa en <br className="d-md-block d-none" /> engagerande och
            tidsbesparande utbildning till ert företag 😃
          </p>
          <div className="d-flex align-items-center justify-content-center mt-2">
            <a  onClick={()=>gotoPage('/contact-new')}  
              class="btn font-20 mobile-font-16 btn-gradient rounded-pill">
              <span> Kontakt </span>
            </a>
          </div>
        </div>
      </Container>
    </section>

    <section className="Booky-slider  top-index">
      <Container>
        <div className="Booky-slider-itembox">
          <Slider {...settings1}>
            <div className="Booky-slider-item">
              <span className="Booky-slider-logo"> booky</span>
              <p className="Booky-slider-text">
                Vi tog hjälp av Docens för att skapa en intern
                utbildningsfilm till en av våra kunder. Docens levererade på
                topp från start till slut. De var med på förmöte, de skötte
                hela produktionen och inkluderade vår kunds grafiska profil
                i redigeringen. Funderar ni på att skapa utbildningsmaterial
                eller utbildningsfilmer så tycker jag verkligen att ni ska
                kontakta Docens.
              </p>
              <div className="Booky-slider-profile d-flex align-items-center justify-content-center gap-6">
                <Image width={73} height={73} src="/assets/images/Charlotte_Dunhoff.png" alt="Office"
                  className="rounded-3" />
                <div>
                  <span className="Booky-slider-profileinfo-text1 d-block mb-1">
                    Charlotte Dunhoff
                  </span>
                  <span className="Booky-slider-profileinfo-text2 d-block">
                    Grundare & VD
                  </span>
                </div>
              </div>
            </div>
            <div className="Booky-slider-item">
              <span className="Booky-slider-logo"> booky</span>
              <p className="Booky-slider-text">
                Vi tog hjälp av Docens för att skapa en intern
                utbildningsfilm till en av våra kunder. Docens levererade på
                topp från start till slut. De var med på förmöte, de skötte
                hela produktionen och inkluderade vår kunds grafiska profil
                i redigeringen. Funderar ni på att skapa utbildningsmaterial
                eller utbildningsfilmer så tycker jag verkligen att ni ska
                kontakta Docens.
              </p>
              <div className="Booky-slider-profile d-flex align-items-center justify-content-center gap-6">
                <Image width={73} height={73} src="/assets/images/Charlotte_Dunhoff.png" alt="Office"
                  className="rounded-3" />
                <div>
                  <span className="Booky-slider-profileinfo-text1 d-block mb-1">
                    Charlotte Dunhoff
                  </span>
                  <span className="Booky-slider-profileinfo-text2 d-block">
                    Grundare & VD
                  </span>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </Container>
    </section>

    <section className="Edu-library  top-index Booky-slider-bootom-section">
      <Container>
        <h3 className="Edu-library-title">Vårt utbildningsbibliotek</h3>
        <h6  className="Edu-library-subtitle">Färdiginspelade kurser med experter </h6>
        <p className="Edu-library-maintext">Våra färdiginspelade kurser är speciellt utformade för att främja inlärning. Med kurshållare som är experter inom sina områden och en högkvalitativ produktion skapar de en idealisk miljö för inlärning och ökar dina möjligheter att utvecklas. </p>
      </Container>
      {/* <Slider {...settings2}> */}
      <div className="edu-custom-slider">
        {allCources?.length > 0 &&
                    allCources.map((item, key) => (
          <div className="Edu-librarybox cursor_class" onClick={()=>gotoPage((item.number_of_courses ? "/package/" : "/open-course-topics/") +
            (item.number_of_courses ? item.package_id : item.id))}>
              <div className="Edu-librarybox-mainimg">
                <Image src={item.image ? item.image : "/assets/images/Videi-1.jpeg"}  alt="Office" />
              </div>
              <span className="Edu-librarybox-logo">
                <Image  src="/assets/images/docens-sm-logo.png" alt="Office" />
              </span>
              <div className="Edu-librarybox-content">
                <h4>{item.title}</h4>
                <p>{item?.auther_details?.name}</p>
              </div>
          </div>
          ))}
        </div>
      {/* </Slider> */}
      <div className="text-center mt-4">
        <Button varient="primary" onClick={()=>gotoPage('/vara-kurser')}>Se alla kurser</Button>
      </div>
    </section>

    <section className="pb-120 top-index">
      <Container fluid>
        <h2 className="text-center font-40 fw-lighter mb-9 mobile-mb-20">
          Vanliga frågor
        </h2>
        <Accordion defaVanliga frågorultActiveKey="0">
        <Accordion.Item className="border-start-0 border-end-0 rounded-0" eventKey="0">
            <Accordion.Header>Hur kan vi som företag få hjälp att skapa en intern kurs eller utbildningsfilm??</Accordion.Header>
            <Accordion.Body>
            Om ni vill skapa en intern utbildningsfilm eller kurs kan vi hjälpa er med allt ifrån att sätta upp en struktur, hitta kurshållare, filmning och redigering till uppladdning. Vi kan hjälpa er med ett utav momenten, eller allihop. Kontakta oss så diskuterar vi fram ett upplägg som passar just er.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="border-start-0 border-end-0 rounded-0" eventKey="1">
            <Accordion.Header>
            Om vi tar hjälp av er att skapa en intern kurs/utbildningsfilm, måste den ligga på Docens plattform?
            </Accordion.Header>
            <Accordion.Body>
            Ni bestämmer själva om ni vill ha er kurs/utbildningsfilm på Docens plattform och ta del utav de verktyg som finns där, eller om ni vill ladda upp dem internt.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="border-start-0 border-end-0 rounded-0" eventKey="2">
            <Accordion.Header>Hur kan vi som företag få hjälp att skapa en intern kurs eller utbildningsfilm??</Accordion.Header>
            <Accordion.Body>
            Om ni vill skapa en intern utbildningsfilm eller kurs kan vi hjälpa er med allt ifrån att sätta upp en struktur, hitta kurshållare, filmning och redigering till uppladdning. Vi kan hjälpa er med ett utav momenten, eller allihop. Kontakta oss så diskuterar vi fram ett upplägg som passar just er.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="border-start-0 border-end-0 rounded-0" eventKey="3">
            <Accordion.Header>
             Är det för mig som privatperson eller företag?
            </Accordion.Header>
            <Accordion.Body>
              Lorem ipsum dolor sit amet consectetur. Mauris adipiscing
              pellentesque est non enim eu id massa. Sit semper adipiscing
              enim cras non porta magna donec. Consectetur fermentum sit
              diam mattis. Imperdiet ipsum ut id sem nulla ipsum in. Et
              blandit id purus enim auctor consectetur risus eros.
              Pellentesque sodales ornare malesuada tellus.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="border-start-0 border-end-0 rounded-0" eventKey="4">
            <Accordion.Header>Vem är läraren?</Accordion.Header>
            <Accordion.Body>
              Lorem ipsum dolor sit amet consectetur. Mauris adipiscing
              pellentesque est non enim eu id massa. Sit semper adipiscing
              enim cras non porta magna donec. Consectetur fermentum sit
              diam mattis. Imperdiet ipsum ut id sem nulla ipsum in. Et
              blandit id purus enim auctor consectetur risus eros.
              Pellentesque sodales ornare malesuada tellus.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="border-start-0 border-end-0 rounded-0" eventKey="5">
            <Accordion.Header>
              Vad händer om jag missar ett tillfälle?
            </Accordion.Header>
            <Accordion.Body>
              Lorem ipsum dolor sit amet consectetur. Mauris adipiscing
              pellentesque est non enim eu id massa. Sit semper adipiscing
              enim cras non porta magna donec. Consectetur fermentum sit
              diam mattis. Imperdiet ipsum ut id sem nulla ipsum in. Et
              blandit id purus enim auctor consectetur risus eros.
              Pellentesque sodales ornare malesuada tellus.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        {/* <Accordion_Custome></Accordion_Custome> */}
      </Container>
    </section>

    <section className="book-section top-index">
      <Container>
        <div className="text-center max-565 mx-auto">
          <h2 className="font-64 mobile-font-30 fw-semibold">
            Vill du veta mer?
          </h2>
          <p className="font-24 mobile-font-13 fw-light">
           Tveka inte att kontakta oss 😃
          </p>
          <div className="d-flex align-items-center justify-content-center mt-2">
            <a onClick={()=>gotoPage('/contact-new')} target="_blank"
              class="btn font-20 mobile-font-16 btn-gradient rounded-pill">
              <span> Kontakt </span>
            </a>
          </div>
        </div>
      </Container>
    </section>
  </div>
  <div className="top-index">
    <Footer />
  </div>

</div>
);
};

export default HomeNew;